.c-wysywig {
	font-size: 16px;
	line-height: 1.6;
	@include media(sm) {
		font-size: 14px;
	}
	*:first-child {
		margin-top: 0 !important;
	}
	*:last-child {
		margin-bottom: 0 !important;
	}
	h1 {
		display: flex;
		align-items: center;
		font-weight: bold;
		font-size: 36px;
		letter-spacing: 0.1em;
		line-height: 1.4;
		margin-bottom: 30px;
		@include media(sm) {
			font-size: 24px;
			margin-bottom: 20px;
			@include media(min) {
				font-size: 20px;
			}
		}
		&:before {
			content: "";
			width: 25px;
			height: 25px;
			background: url(../images/icon-titA.svg) center no-repeat;
			background-size: contain;
			margin-right: 10px;
			flex-shrink: 0;
			@include media(sm) {
				width: 15px;
				height: 15px;
			}
		}
	}

	h2 {
		display: flex;
		align-items: center;
		font-size: 24px;
		line-height: 1.5;
		font-weight: bold;
		margin-bottom: 30px;
		border-left: 2px solid $main;
		padding-left: 10px;
		@include media(sm) {
			font-size: 20px;
			margin-bottom: 20px;
		}
	}
	h3 {
		display: flex;
		align-items: center;
		font-size: 20px;
		line-height: 1.5;
		font-weight: bold;
		margin-bottom: 20px;
		@include media(tab) {
			@include media(sm) {
				font-size: 18px;
				margin-bottom: 10px;
			}
		}
		&:before {
			content: "";
			width: 14px;
			height: 3px;
			background: $main;
			margin-right: 10px;
			flex-shrink: 0;
		}
	}
	h4 {
		display: flex;
		align-items: center;
		font-size: 18px;
		line-height: 1.5;
		font-weight: bold;
		margin-bottom: 20px;
		&:before {
			content: "";
			width: 10px;
			height: 2px;
			background: $main;
			margin-right: 10px;
			flex-shrink: 0;
		}
		@include media(sm) {
			font-size: 16px;
			margin-bottom: 10px;
		}
	}
	h5 {
		display: flex;
		align-items: center;
		font-size: 16px;
		line-height: 1.5;
		font-weight: bold;
		margin-bottom: 20px;
		&:before {
			content: "";
			width: 5px;
			height: 5px;
			background: $main;
			margin-right: 10px;
			flex-shrink: 0;
		}
		@include media(sm) {
			font-size: 14px;
			margin-bottom: 10px;
		}
	}
	h6 {
		font-size: 14px;
		line-height: 1.5;
		font-weight: bold;
		margin-bottom: 20px;
		@include media(sm) {
			font-size: 12px;
			margin-bottom: 10px;
		}
	}
	h1,
	h2,
	p,
	pre,
	ul,
	ol,
	blockquote,
	table {
		+ h1,
		+ h2 {
			margin-top: 50px;
			@include media(sm) {
				margin-top: 30px;
			}
		}
	}
	p,
	pre,
	ul,
	ol,
	table,
	blockquote {
		margin-bottom: 20px;
		@include media(sm) {
			margin-bottom: 10px;
		}
	}

	a {
		color: $link;
		text-decoration: underline;
		word-break: break-all;
		&[target="_blank"]:not(.is-noicon) {
			display: inline-block;
			&:after {
				content: "\f08e";
				margin-left: 5px;
				font-family: "Font Awesome 6 Pro";
			}
		}
	}
	hr {
		margin: 50px 0;
	}
	iframe {
		max-width: 100%;
	}
	blockquote {
		position: relative;
		padding: 10px 15px 10px 60px;
		box-sizing: border-box;
		font-style: italic;
		background: #efefef;
		color: #555;
	}

	blockquote:before {
		display: inline-block;
		position: absolute;
		top: 18px;
		left: 15px;
		content: "\f10d";
		font-family: "Font Awesome 6 Pro";
		color: #cfcfcf;
		font-size: 30px;
		line-height: 1;
		font-weight: 900;
	}

	blockquote p {
		padding: 0;
		margin: 10px 0;
	}

	blockquote cite {
		display: block;
		text-align: right;
		color: #888888;
		font-size: 0.9em;
	}

	img {
		float: none !important;
		display: block !important;
		height: auto;
	}
	.aligncenter {
		display: block;
		margin-left: auto !important;
		margin-right: auto !important;
	}
	.mt-image-left {
		margin-right: auto !important;
	}
	.mt-image-right {
		margin-left: auto !important;
	}
	ul {
		padding-left: 1em;
		position: relative;
	}
	ul li {
		padding: 0.5em 0;
		list-style-type: none !important;
	}

	ul li:before {
		font-family: "Font Awesome 6 Pro";
		content: "\f0da";
		position: absolute;
		left: 0; /*左端からのアイコンまで*/
		color: $link;
		transform: translateY(1px);
	}
	ol {
		list-style: decimal;
		list-style-position: inside;
		li {
			padding: 0.5em 0;
		}
	}
	.mt-be-columns {
		justify-content: space-between;
		margin-bottom: 30px;
		@include media(sm) {
			display: block !important;
		}
	}
	.mt-be-column {
		@include media(sm) {
			width: 100% !important;
			+ .mt-be-column {
				margin-top: 10px;
			}
		}
		&:nth-child(odd) {
			width: 30%;
		}
		&:nth-child(even) {
			width: 65%;
		}
	}
	table {
		@include media(sm) {
			background: none;
			display: block;
			height: auto !important;
			tbody,
			tr,
			td {
				display: block;
				width: 100% !important;
				height: auto !important;
			}
		}
		tr {
			+ tr {
				@include media(sm) {
					margin-top: 20px;
				}
				td {
					border-top: 2px solid white;
				}
			}
			td {
				padding: 10px 15px;
				word-break: break-all;
				@include media(sm) {
					padding: 10px;
				}
				&:first-child {
					white-space: nowrap;
					background-color: #f5f5f5;
					@include media(sm) {
						white-space: unset;
					}
				}
			}
		}
	}
	.youtube {
		width: 100%;
		padding-top: 56.25%;
		position: relative;
		margin-bottom: 30px;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
