.c-pankuzu {
  width: 100%;
  overflow: auto;
  display: flex;
  white-space: nowrap;
  padding-bottom: 10px;
  @include media(sm) {
  }
  li {
    font-size: 16px;
    @include media(sm) {
      font-size: 14px;
    }
    + li {
      &:before {
        content: '>';
        margin: 0 5px;
      }
    }
  }
}
