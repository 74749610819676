.c-pager {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  @include media(sm) {
    margin-top: 50px;
  }
  &:blank {
    margin-top: 0;
  }
  a {
    font-size: 20px;
    font-weight: bold;
    color: $link;
    @include media(sm) {
      font-size: 18px;
    }
    i {
    }
  }
  &__prev {
    margin-right: 50px;
    @include media(sm) {
      margin-right: 30px;
    }
    i {
      margin-right: 20px;
      @include media(sm) {
        margin-right: 10px;
      }
    }
  }
  &__next {
    i {
      margin-left: 20px;
      @include media(sm) {
        margin-right: 10px;
      }
    }
  }
}
