.l-ftr {
  margin-top: 150px;
  @include media(sm) {
    margin-top: 80px;
  }
  &-top {
    background-color: #f5f5f5;
    padding: 40px 0;
    &__flex {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @include media(sm) {
        display: block;
      }
      .left {
        flex-shrink: 0;
        margin-right: 80px;
        @include media(tab) {
          margin-right: 40px;
          @include media(sm) {
            margin-right: 0;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
      .right {
        font-size: 14px;
        line-height: 1.2;
        width: 100%;
        @include media(sm) {
        }
        .pages {
          display: grid;
          grid-template-columns: 33% 33% 33%;
          grid-template-areas:
          "areaB areaA areaA"
          "areaC areaA areaA"
          "areaD areaA areaA"
          "areaE areaA areaA";
          margin-top: -20px;
          @include media(tab) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: -10px;
          }
          li {
            @include media(tab) {
              width: 50%;
            }
            &:nth-child(1) {
              grid-area: areaB;
            }
            &:nth-child(2) {
              grid-area: areaC;
            }
            &:nth-child(3) {
              grid-area: areaD;
            }
            &:nth-child(4) {
              grid-area: areaE;
            }
            &:nth-child(5) {
              grid-area: areaA;
              @include media(tab) {
                width: 100%;
              }
            }
            a {
              display: inline-block;
              border-bottom: 1px solid transparent;
              padding-bottom: 2px;
              font-weight: bold;
              margin-top: 20px;
              @include media(tab) {
                margin-top: 10px;
              }
              &:hover {
                opacity: 1;
                color: $link;
              }
            }
            .child {
              padding-left: 1em;
              display: flex;
              flex-wrap: wrap;
              > div {
                width: 50%;
              }
              a {
                font-weight: normal;
              }
            }
          }
        }
      }
    }
    &__logo {
      margin-bottom: 10px;
    }
    &__addr {
      p {
        font-size: 14px;
        line-height: 1.6;
        @include media(sm) {
          font-size: 12px;
        }
        + p {
          margin-top: 5px;
        }
        &.addr {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          @include media(sm) {
          }
          .contact {
            border-radius: 50%;
            color: white;
            width: 50px;
            height: 50px;
            background: url(../images/envelope-solid.svg) center no-repeat #000;
            background-size: auto 23px;
            flex-shrink: 0;
            margin-left: 20px;
            flex-shrink: 0;
            transform: translateY(5px);
            @include media(sm) {
              margin-left: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  &-bottom {
    padding: 20px 0;
    &__flex {
      @include media(sm) {
      }
      .other {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media(sm) {
          flex-direction: column;
          width: 100%;
        }
        a {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 1.2;
          padding: 0 20px 10px;
          border-bottom: 2px solid $main;
          width: 48%;
          @include media(sm) {
            width: 100%;
            font-size: 12px;
            padding: 0 10px 10px;
          }
          img {
            width: 32px;
            margin-right: 20px;
            flex-shrink: 0;
            @include media(sm) {
              width: 25px;
              margin-right: 10px;
            }
          }
          i {
            color: $link;
            margin-left: auto;
            flex-shrink: 0;
            padding-left: 20px;
            @include media(sm) {
              margin-left: auto;
              padding-left: 10px;
            }
          }
          + a {
            margin-left: 30px;
            @include media(sm) {
              margin-left: 0;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  &-copy {
    background: $main;
    padding: 10px 0;
    &__flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      line-height: 1.5;
      @include media(sm) {
        display: block;
      }
      .cc {
        display: flex;
        align-items: center;
        @include media(sm) {
          justify-content: center;
        }
        img {
          margin-right: 10px;
          flex-shrink: 0;
        }
        p {
          color: white;
          a {
            text-decoration: underline;
            color: white;
          }
        }
      }
      .copy {
        color: white;
        @include media(sm) {
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
  &__copy {
    background: $main;
    text-align: center;
    color: white;
    font-size: 12px;
    padding: 10px 0;
  }
}
