.c-btnA {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50px;
  max-width: 100%;
  border: 2px solid $main;
  color: white;
  position: relative;
  padding: 10px 50px;
  color: $link;
  font-size: 16px;
  line-height: 1.5;
  @include media(sm) {
    font-size: 14px;
    @include media(min) {
      padding: 10px 20px;
    }
  }
  &:after {
    content: "";
    width: 26px;
    height: 2px;
    background: $sub;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto 0;
    transform: translateX(50%);
    transition: 0.3s ease;
  }
  &:hover {
    background: $main;
    color: white;
    opacity: 1;
    &:after {
      transform: translateX(0);
    }
  }
  &--w {
    border-color: #fff;
    color: white;
  }
  &--blue {
    border-color: $sub2;
    color: $sub2;
    &:hover {
      background: $sub2;
      color: white;
    }
  }
}
.c-backbtn {
  text-align: center;
  margin-top: 100px;
  @include media(sm) {
    margin-top: 50px;
  }
}