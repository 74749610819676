.c-search {
  margin-bottom: 30px;
  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    &:before {
      content: "\f002";
      font-family: "Font Awesome 6 Pro";
      font-weight: 400;
      color: $main;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      font-size: 20px;
      line-height: 35px;
      width: 35px;
      height: 35px;
      text-align: center;
    }

    input {
      width: 100%;
      padding-left: 10px;
    }
    button {
      width: 35px;
      height: 35px;
      flex-shrink: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
}
