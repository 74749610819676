.c-intro {
  text-align: center;
  margin-bottom: 80px;
  @include media(sm) {
    margin-bottom: 40px;
  }
  &__text {
    font-size: 16px;
    line-height: 2;
    @include media(sm) {
      font-size: 14px;
    }
    a {
      color: $link;
      border-bottom: 1px solid $link;
    }
  }
}
