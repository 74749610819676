.c-linklist {
  display: flex;
  margin-top: -5%;
  flex-wrap: wrap;
  @include media(sm) {
    justify-content: space-between;
  }
  li {
    width: 30%;
    margin-right: 5%;
    margin-top: 5%;
    @include media(sm) {
      width: 48%;
      margin-right: 0;
      @include media(min) {
        width: 100%;
      }
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  &__link {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 10px 10.24px rgba(0, 0, 0, 0.04);
    &:hover {
      box-shadow: 0px 10px 20.24px 2.76px rgba(0, 0, 0, 0.04);
    }
  }
  &__image {
    // aspect-ratio: 3/2;
    width: 100%;
    padding-top: 66.6666%;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__textarea {
    padding: 15px;
    @include media(sm) {
      padding: 10px;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      margin-bottom: 10px;
      @include media(sm) {
        font-size: 14px;
      }
    }
    .text {
      font-size: 13px;
      line-height: 1.6;
      margin-bottom: 10px;
      @include media(sm) {
        font-size: 12px;
      }
    }
    .more {
      text-align: right;
      color: $link;
      font-size: 16px;
      margin-top: auto;
      @include media(sm) {
        font-size: 14px;
      }
      i {
        margin-left: 10px;
      }
    }
  }
}
