@charset "utf-8";
// 03__フォントサイズとラインハイトまとめて指定
// ----------------------------------------------------------
@mixin fz($size, $height: false, $weight: false) {
	font-size: #{$size}px;

	@if $weight {
		font-weight: $weight;
	}

	@if $height {
		line-height: $height / $size;
	}
}
@mixin fzr($size, $height: false, $weight: false) {
	font-size: #{$size / 10}rem;

	@if $weight {
		font-weight: $weight;
	}

	@if $height {
		line-height: $height / $size;
	}
}

// 06__media query
// ----------------------------------------------------------
@mixin media($size, $px: false) {
	@if ($size == "tab") {
		@media screen and (max-width: $breakPointTab - 1) {
			@content;
		}
	} @else if ($size == "tabup") {
		@media screen and (min-width: $breakPointTab), print {
			@content;
		}
	}
	@if ($size == "sm") {
		@media screen and (max-width: $breakPoint - 1) {
			@content;
		}
	} @else if ($size == "lg") {
		@media screen and (min-width: $breakPoint), print {
			@content;
		}
	}
	@if ($size == "min") {
		@media screen and (max-width: $breakPointMin - 1) {
			@content;
		}
	} @else if ($size == "minup") {
		@media screen and (min-width: $breakPointMin), print {
			@content;
		}
	}

	@if ($px) {
		@if ($size == "max-width") {
			@media screen and (max-width: $px) {
				@content;
			}
		} @else if ($size == "min-width") {
			@media screen and (min-width: $px) {
				@content;
			}
		} @else if ($size == "max-height") {
			@media screen and (max-height: $px) {
				@content;
			}
		} @else if ($size == "min-height") {
			@media screen and (min-height: $px) {
				@content;
			}
		}
	}
}

// 12__formスタイルリセット
// ----------------------------------------------------------
@mixin formReset() {
	input,
	button,
	textarea,
	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: none;
		border: none;
		border-radius: 0;
		margin: 0;
		outline: none;
		padding: 0;
	}

	select::-ms-expand {
		display: none;
	}
}

// 13__ie11 (lg / sm)
// ----------------------------------------------------------
@mixin ie11($size: false) {
	html[data-browser="ie"][data-browserversion="11"] & {
		@if ($size) {
			@if ($size == "lg") {
				@media screen and (min-width: $breakPoint) {
					@content;
				}
			} @else if ($size == "sm") {
				@media screen and (max-width: $breakPoint - 1) {
					@content;
				}
			}
		} @else {
			@content;
		}
	}
}

// 14__edge (lg / sm)
// ----------------------------------------------------------
@mixin edge($size: false, $engine: "edgehtml") {
	html[data-browser="edge"][data-browserengine="#{$engine}"] & {
		@if ($size) {
			@if ($size == "lg") {
				@media screen and (min-width: $breakPoint) {
					@content;
				}
			} @else if ($size == "sm") {
				@media screen and (max-width: $breakPoint - 1) {
					@content;
				}
			}
		} @else {
			@content;
		}
	}
}

// 15__firefox (lg / sm)
// ----------------------------------------------------------
@mixin firefox($size: false) {
	html[data-browser="firefox"] & {
		@if ($size) {
			@if ($size == "lg") {
				@media screen and (min-width: $breakPoint) {
					@content;
				}
			} @else if ($size == "sm") {
				@media screen and (max-width: $breakPoint - 1) {
					@content;
				}
			}
		} @else {
			@content;
		}
	}
}

// 16__safari (lg / sm)
// ----------------------------------------------------------
@mixin safari($size: false) {
	@if ($size == "sm") {
		html[data-browser="mobile-safari"] & {
			@content;
		}
	} @else {
		html[data-browser="safari"] & {
			@content;
		}
	}
}

// 17__tablet (portrait / landscape)
// ----------------------------------------------------------
@mixin tablet($orientation: false) {
	@if ($orientation) {
		html[data-type="tablet"][data-orientation="#{$orientation}"] & {
			@content;
		}
	} @else {
		html[data-type="tablet"] & {
			@content;
		}
	}
}

// 18__ipados (portrait / landscape) ラップトップだけどタッチサポートしているデバイス全般
// ----------------------------------------------------------
@mixin ipados($orientation: false) {
	@if ($orientation) {
		html[data-type="laptop"][data-os="mac-os"][data-browser="safari"][data-touchsupport="true"][data-orientation="#{$orientation}"]
			& {
			@content;
		}
	} @else {
		html[data-type="laptop"][data-os="mac-os"][data-browser="safari"][data-touchsupport="true"] & {
			@content;
		}
	}
}
// 23__placeholder
// ----------------------------------------------------------
@mixin placeholder {
	&:placeholder {
		@content;
	}

	&:placeholder-shown {
		@content;
	}

	&::-webkit-input-placeholder {
		@content;
	}

	&:-moz-placeholder {
		@content;
	}

	&::-moz-placeholder {
		@content;
	}

	&:-ms-input-placeholder {
		@content;
	}
}

// 24__picture100
// ----------------------------------------------------------
@mixin picture100 {
	picture,
	source,
	img {
		@include object-fit(cover);

		height: 100%;
		width: 100%;
	}
}

@function round-decimal($number, $digits: 0) {
	@return to-fixed($number, $digits, "round");
}
@function to-fixed($number, $digits: 0, $round: "round") {
	$n: 1;
	// $number must be a number
	@if type-of($number) != number {
		@warn '#{ $number } is not a number.';
		@return $number;
	}
	// $digits must be a unitless number
	@if type-of($digits) != number {
		@warn '#{ $digits } is not a number.';
		@return $number;
	} @else if not unitless($digits) {
		@warn '#{ $digits } has a unit.';
		@return $number;
	}
	@for $i from 1 through $digits {
		$n: $n * 10;
	}
	@if $round == "round" {
		@return round($number * $n) / $n;
	} @else if $round == "ceil" {
		@return ceil($number * $n) / $n;
	} @else if $round == "floor" {
		@return floor($number * $n) / $n;
	} @else {
		@warn '#{ $round } is undefined keyword.';
		@return $number;
	}
}

@function ptov($value) {
	@return round-decimal($value / 1000 * 100, 1) + rem;
}
@function ptovs($value) {
	@return round-decimal($value / 428 * 100, 1) + rem;
}
@include media(tab) {
	p {
		outline: none;
	}
	@include media(sm) {
		p {
			outline: none;
		}
		@include media(min) {
			p {
				outline: none;
			}
		}
	}
}
@mixin boxshadow() {
	box-shadow: 0 0 98px rgba($color: #000000, $alpha: 0.07);
	@include media(tab) {
		box-shadow: 0 0 80px rgba($color: #000000, $alpha: 0.07);
		@include media(sm) {
			box-shadow: 0 0 50px rgba($color: #000000, $alpha: 0.07);
			@include media(min) {
				box-shadow: 0 0 30px rgba($color: #000000, $alpha: 0.07);
			}
		}
	}
}
