.c-titA {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 36px;
  letter-spacing: 0.1em;
  line-height: 1.4;
  margin-bottom: 40px;
  @include media(sm) {
    font-size: 24px;
    margin-bottom: 20px;
    @include media(min) {
      font-size: 20px;
    }
  }
  &:before {
    content: "";
    width: 25px;
    height: 25px;
    background: url(../images/icon-titA.svg) center no-repeat;
    background-size: contain;
    margin-right: 10px;
    flex-shrink: 0;
    @include media(sm) {
      width: 15px;
      height: 15px;
    }
  }
}
.c-titB {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 30px;
  margin-left: -24px;
  @include media(tab) {
    margin-left: 0;
    @include media(sm) {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
  &__text {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  &__ja {
    margin-right: 10px;
  }
  &__en {
    font-style: italic;
    font-weight: normal;
    font-size: 90%;
  }
  &:before {
    content: "";
    width: 14px;
    height: 3px;
    background: $main;
    margin-right: 10px;
    flex-shrink: 0;
  }
}
