* {
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
	&:before,
	&:after {
		box-sizing: border-box;
	}
}

html {
	font-size: 10px;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	@include media("min-width", "1600px") {
		//font-size: 12px;
	}
	@include media(sm) {
		//font-size: 8px;
	}
}
body {
	font-family: 'Noto Sans JP',"Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
	font-size: 1.6rem;
	line-height: 1;
	letter-spacing: .08em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	color: #222222;
	overflow-x: hidden;
	@media all and (-ms-high-contrast: none), print {
		// font-family:'メイリオ', 'Meiryo', sans-serif;
	}
	@include media(sm) {
		font-size: 1.4rem;
	}
}

a,
button {
	text-decoration: none;
	color: #222222;
	outline: none;
	@include media(lg) {
		transition: 0.3s ease;
		&:hover {
			opacity: 0.7;
		}
	}
}

// image

img,
svg,
video {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
	display: inline-block;
}

// table

table {
	border-collapse: collapse;
	max-width: 100%;
	width: 100%;

	th,
	td {
		vertical-align: top;
	}
}

// IE11のクリアボタンを非表示

input::-ms-clear {
	display: none;
}

ul,
ol {
	list-style-position: inside;
	list-style: none;
}

input,
button,
textarea,
select {
	// -webkit-appearance: none;
	// -moz-appearance: none;
	// appearance: none;
	// background: none;
	// border: none;
	// border-radius: 0;
	// margin: 0;
	// outline: none;
	// padding: 0;
	font-family: inherit;
}

select::-ms-expand {
	display: none;
}

button {
	&:focus {
		outline: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
}
p {
}
