.c-inner {
	max-width: 1200px;
	width: calc(100% - 40px);
	margin-left: auto;
	margin-right: auto;
	&--small {
		max-width: 1024px;
	}
	@include media(sm) {
	}
}
@include media(tabup) {
	.c-tab {
		display: none !important;
	}
}
@include media(tab) {
	.c-tab_none {
		display: none !important;
	}
}
@include media(lg) {
	.c-sp {
		display: none !important;
	}
}
@include media(sm) {
	.c-pc {
		display: none !important;
	}
}
.c-ofi {
	object-fit: cover;
	font-family: "object-fit: cover;, object-position: center;";
	width: 100%;
	height: 100%;
	object-position: center;
}
.c-anchor {
	margin-top: -101px;
	padding-top: 101px;
	@include media(tab) {
		margin-top: -83px;
		padding-top: 83px;
		@include media(sm) {
			margin-top: -63px;
			padding-top: 63px;
		}
	} 
}
