.c-mv {
  position: relative;
  padding: 60px 0 1px;
  margin-bottom: 80px;
  @include media(sm) {
    margin-bottom: 40px;
    padding: 30px 0 1px;
  }
  &__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 120px;
    width: calc(100vw - ((100vw - 1024px) / 2));
    background-color: #f5f5f5;
    z-index: -1;
    @include media(tab) {
      width: 90%;
      height: 80px;
    }
  }
  &__image {
    height: 170px;
    width: 60%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include media(sm) {
      height: 120px;
    }
    img {
      object-fit: cover;
      font-family: "object-fit: cover;, object-position: center;";
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }
  &__title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 90px;
    @include media(sm) {
      margin-bottom: 50px;
    }
    img {
      width: 25px;
      margin-right: 10px;
      transform: translateY(5px);
      @include media(sm) {
        width: 15px;
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      text-shadow: 0 0 1px white, 0 0 1px white, 0 0 1px white, 0 0 1px white, 0 0 1px white;
      .ja {
        font-size: 30px;
        font-weight: bold;
        @include media(sm) {
          font-size: 22px;
        }
      }
      .en {
        font-size: 16px;
        margin-top: 10px;
        @include media(sm) {
          font-size: 14px;
        }
      }
    }
  }
}
