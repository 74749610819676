body {
  padding-top: 145px;
  @include media(tab) {
    padding-top: 75px;
    @include media(min) {
      padding-top: 61px;
    }
  }
}
.l-hdr {
  background-color: #fff;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.6);
  &-top {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @include media(tab) {
      margin-bottom: 0;
      position: relative;
      z-index: 9999;
      @include media(min) {
      }
    }
    &__logo {
      a {
        img {
          @include media(tab) {
            width: 200px;
            @include media(min) {
              width: 150px;
            }
          }
        }
      }
    }
    &__cv {
      display: flex;
      align-items: center;
      margin-left: auto;
      @include media(sm) {
        display: none;
      }
      a {
        background: $main;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding: 0 20px;
        border: 2px solid $main;
        font-size: 18px;
        @include media(tab) {
          font-size: 12px;
          padding: 0 10px;
        }
        &:hover {
          opacity: 1;
          color: $link;
          background-color: #fff;
        }
        i {
          margin-right: 10px;
          @include media(tab) {
          }
        }
        &.blue {
          background: $sub2;
          border-color: $sub2;
          &:hover {
            background-color: #fff;
            color: $sub2;
          }
        }
        + a {
          margin-left: 14px;
        }
        .text {
          @include media(tab) {
          }
        }
      }
    }
    &__menu {
      @include media(tab) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 1;
        position: relative;
        z-index: 2;
        margin-left: 20px;
        @include media(sm) {
          margin-left: auto;
        }
        .line {
          position: relative;
          width: 36px;
          height: 24px;
          display: inline-block;
          transition: all 0.5s;
          span {
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #000;
            border-radius: 4px;
            display: inline-block;
            transition: all 0.5s;
            &:nth-of-type(1) {
              top: 0;
            }

            &:nth-of-type(2) {
              top: 11px;
            }

            &:nth-of-type(3) {
              bottom: 0;
            }
          }
        }
        &.is-open .line span:nth-of-type(1) {
          transform: translateY(11px) rotate(45deg);
        }

        &.is-open .line span:nth-of-type(2) {
          opacity: 0;
        }

        &.is-open .line span:nth-of-type(3) {
          transform: translateY(-11px) rotate(-45deg);
        }
      }
    }
  }
  &-bottom {
    @include media(tab) {
      position: absolute;
      top: 100%;
      right: 0;
      height: calc(100vh - 75px);
      width: 320px;
      background-color: rgba($color: #fff, $alpha: 0.9);
      z-index: 99;
      transform: translateX(100%);
      transition: 0.3s ease;
      overflow: auto;
      padding-bottom: 20px;
      @include media(min) {
        height: calc(100vh - 61px);
      }
      &.is-open {
        transform: translateX(0);
      }
    }
    &__inner {
      @include media(tab) {
      }
    }
    ul {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: space-between;
      @include media(tab) {
        display: block;
        text-align: left;
      }
      li {
        position: relative;
        padding: 0 26px;
        @include media("max-width", "1200px") {
          padding: 0;
          width: 100%;
          @include media(tab) {
            width: 100%;
            border-bottom: 1px solid $main;
          }
        }
        &:after {
          content: "";
          width: 2px;
          height: 18px;
          background-color: $main;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto 0;
          @include media(tab) {
            content: none;
          }
        }
        &:first-child {
          @include media(tab) {
            border-top: 1px solid $main;
          }
          &:before {
            content: "";
            width: 2px;
            height: 18px;
            background-color: $main;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto 0;
            @include media(tab) {
              content: none;
            }
          }
        }
        a {
          font-size: 16px;
          line-height: 1.2;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          border-bottom: 2px solid #fff;
          white-space: nowrap;
          @include media("max-width", "1200px") {
            font-size: 14px;
            @include media(tab) {
              padding: 10px 20px;
              width: 100%;
              justify-content: flex-start;
              font-size: 14px;
              border-bottom-width: 0;
              white-space: normal;
              br {
                display: none;
              }
              &:after {
                content: "\f054";
                font-family: "Font Awesome 6 Pro";
                font-weight: 400;
                color: $link;
                margin-left: auto;
              }
            }
          }
          &:hover {
          }
        }
        @include media(tabup) {
          &:hover {
            .child {
              opacity: 1;
              visibility: visible;
            }
          }
        }
        @include media(tab) {
          &.has-child {
            padding-right: 47px;
            a {
              &:after {
                display: none;
              }
            }
            .toggle {
              position: absolute;
              top: 0;
              right: 7px;
              width: 40px;
              height: 40px;
              cursor: pointer;
              &:before,
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                margin: auto;
                width: 50%;
                height: 2px;
                background: $main;
                transform: translate(-50%, -50%);
                transition: 0.3s ease;
              }
              &:after {
                transform: translate(-50%, -50%) rotate(90deg);
              }
              &.is-open {
                &:after {
                  transform: translate(-50%, -50%) rotate(0deg);
                }
              }
            }
          }
        }
        .child {
          @include media(tabup) {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            visibility: hidden;
            transition: 0.3s ease;
            padding: 20px 0 0;
          }
          @include media(tab) {
            display: none;
          }
          @include media(tabup) {
            &:after {
              position: absolute;
              top: 10px;
              right: 0;
              left: 0;
              width: 0;
              height: 0;
              margin: 0 auto;
              border-width: 0 10px 10px;
              border-style: solid;
              border-color: transparent transparent #fff;
              content: "";
            }
          }
          &__inner {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: #fff;
            padding: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2%);
            @include media(tab) {
              box-shadow: none;
              background: none;
              padding: 0;
            }
            a {
              white-space: nowrap;
              min-height: auto;
              @include media(tab) {
                width: 100%;
                font-size: 90%;
                padding-left: 40px;
              }
              &:hover {
                border-color: $main;
              }
              + a {
                margin-top: 15px;
                @include media(tab) {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
    &__cv {
      @include media(tab) {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20px 20px 0;
        a {
          background: $main;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          padding: 0 20px;
          border: 2px solid $main;
          font-size: 14px;
          width: 100%;
          &:hover {
            opacity: 1;
            color: $link;
            background-color: #fff;
          }
          i {
            margin-right: 10px;
          }
          &.blue {
            background: $sub2;
            border-color: $sub2;
            &:hover {
              color: $sub2;
              background-color: #fff;
            }
          }
          + a {
            margin-top: 15px;
          }
          .text {
          }
        }
      }
    }
  }
}
