@charset "utf-8";
//tab
$breakPointTab: 1025px;
//sm
$breakPoint: 769px;
//min
$breakPointMin: 501px;

$main: #8cc83f;
$sub: #ff9e3f;
$sub2: #489ce4;
$sub3: #fe808b;

$link: #70a032;