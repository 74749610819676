.c-articleblock {
  &-flex {
    display: flex;
    justify-content: space-between;
    @include media(sm) {
      display: block;
    }
    &__main {
      width: 100%;
    }
    &__right {
      width: 220px;
      flex-shrink: 0;
      margin-left: 60px;
      @include media(sm) {
        width: 100%;
        margin-top: 50px;
        margin-left: 0;
      }
    }
  }
  &-titlearea {
    margin-bottom: 50px;
    &__date {
      font-size: 13px;
      color: #5f5f5f;
      margin-bottom: 10px;
      @include media(sm) {
        font-size: 12px;
      }
      i {
        margin-left: 5px;
      }
      &--orange {
        color: $sub;
      }
      &--blue {
        color: $sub2;
      }
    }
    &__label {
      font-size: 14px;
      margin-bottom: 10px;
      background: $sub2;
      display: inline-block;
      padding: 5px 10px;
      color: white;
      @include media(sm) {
        font-size: 13px;
      }
      i {
        margin-right: 5px;
      }
    }
    &__title {
      font-size: 22px;
      font-weight: bold;
      line-height: 1.6;
      margin-bottom: 10px;
      @include media(sm) {
        font-size: 18px;
      }
    }
    &__name {
    }
    .meta-wrap {
      display: flex;
      margin-bottom: 10px;
      p {
        font-size: 18px;
        font-weight: bold;
        color: #5f5f5f;
        @include media(sm) {
          font-size: 16px;
        }
        + p {
          margin-left: 10px;
        }
      }
    }
    &__cat {
      display: flex;
      flex-wrap: wrap;
      margin-top: -10px;
      a {
        margin-top: 10px;
        margin-right: 10px;
        color: white;
        padding: 5px;
        font-size: 12px;
        background-color: rgb(73, 73, 73);
        @include media(sm) {
        }
      }
      .green {
        background: $main;
      }
      .orange {
        background: $sub;
      }
      .blue {
        background: $sub2;
      }
      .pink {
        background: $sub3;
      }
    }
  }
  &__eyecatch {
    margin-bottom: 50px;
    text-align: center;
    .image {
      display: inline-block;
      position: relative;
    }
    img {
    }
    .cap {
      text-align: right;
      color: rgba($color: #000000, $alpha: 0.5);
      font-size: 12px;
      line-height: 1.5;
      margin-top: 5px;
    }
    i {
      position: absolute;
      top: 10px;
      right: 10px;
      color: white;
      font-size: 20px;
      opacity: 0.8;
    }
  }
  &__name {
    color: #666666;
    font-size: 13px;
    letter-spacing: 0.05em;
    line-height: 1.5;
  }
}
.c-sideA {
  dl {
    + dl {
      margin-top: 30px;
    }
    dt {
      color: $link;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
      border-bottom: 1px solid #aeaeae;
    }
    dd {
      + dd {
        margin-top: 10px;
      }
      a {
        font-size: 13px;
        line-height: 1.4;
        display: inline-flex;
        align-items: center;
        &:before {
          content: "";
          width: 5px;
          height: 2px;
          background-color: #aeaeae;
          margin-right: 5px;
          flex-shrink: 0;
        }
      }
      ul {
        padding-left: 1em;
        li {
          margin-top: 5px;
        }
      }
    }
  }
}
.c-articlelist {
  &__item {
    &.is-end {
      opacity: 0.4;
    }

    a {
      display: flex;
      padding-bottom: 15px;
      border-bottom: 1px solid #aeaeae;
    }
    + .c-articlelist__item {
      a {
        padding-top: 15px;
      }
    }
  }
  &__textarea {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__image {
    flex-shrink: 0;
    margin-right: 20px;
    width: 100px;
    img {
      height: auto;
    }
  }
  > a {
  }
  &__meta {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: -10px;
    p {
      margin-right: 20px;
      margin-top: 10px;
    }
  }
  &__date {
    font-size: 13px;
    color: #5f5f5f;
    @include media(sm) {
      font-size: 12px;
    }
    i {
      margin-left: 5px;
    }
    &--orange {
      color: $sub;
    }
  }
  &__end {
    color: #bbb;
    font-size: 13px;
    @include media(sm) {
      font-size: 12px;
    }
  }
  .meta01,
  .meta02 {
    font-size: 13px;
    line-height: 1.4;
    text-decoration: underline;
    @include media(sm) {
      font-size: 11px;
    }
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.6;
    margin-bottom: 10px;
    @include media(sm) {
      font-size: 14px;
    }
  }
  &__ex {
    font-size: 14px;
    line-height: 1.6;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    width: 100%;
    overflow: hidden;
    @include media(sm) {
      font-size: 12px;
    }
  }
  &__cat {
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    justify-content: flex-end;
    span {
      margin-top: 10px;
      margin-right: 10px;
      color: white;
      padding: 5px;
      font-size: 12px;
      background-color: rgb(73, 73, 73);
      @include media(sm) {
      }
    }
    .green {
      background: $main;
    }
    .orange {
      background: $sub;
    }
    .blue {
      background: $sub2;
    }
    .pink {
      background: $sub3;
    }
  }
}
.c-articlelistB {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 20px;
  @include media(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
  &__item {
  }
  &__image {
    aspect-ratio: 1/1;
    background: gray;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__textarea {
  }
  &__date {
    color: $sub2;
    margin-top: 10px;
    font-size: 13px;
    @include media(sm) {
      font-size: 12px;
    }
    i {
      margin-right: 3px;
      width: 13px;
      text-align: center;
      @include media(sm) {
        width: 12px;
      }
    }
  }
  &__author {
    color: rgba($color: #000000, $alpha: 0.6);
    font-size: 13px;
    margin-top: 7px;
    @include media(sm) {
      font-size: 12px;
    }
    i {
      margin-right: 3px;
      font-size: 12px;
      width: 13px;
      text-align: center;
      @include media(sm) {
        font-size: 11px;
        width: 12px;
      }
    }
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.6;
    margin-top: 5px;
    @include media(sm) {
      font-size: 14px;
    }
  }
}
